import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import About from '../components/About'
import EduExp from '../components/EduExp'
import Portfolio from '../components/Portfolio'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import BackToTop from '../components/BackToTop'

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-r from-[#e9e8fd] to-[#ffffff] font-montserrat">
      <Navbar />
      <div className="flex flex-col px-16 lg:px-56 pt-24 lg:pt-48 gap-y-52 lg:gap-y-72">
        <Header />
        <About />
        <EduExp />
        <Portfolio />
        <Contact />
      </div>
      <Footer />
      <BackToTop />
    </div>
  )
}

export default Home
