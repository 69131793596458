import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://alihandemirdas.com.tr/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Bir hata oluştu.');
      }

      const result = await response.json();
      setSubmissionSuccess(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
    } catch (error) {
      setSubmissionSuccess(false);
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="iletisim" className="grid pb-20 gap-x-12 gap-y-6">
      <div className="flex flex-col justify-center items-center w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="40"
          height="40"
          className="mb-4"
        >
          <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
        </svg>
        <h1 className="text-4xl font-bold mb-4 text-center">İletişim</h1>
        <p className="text-xl font-light text-center">
          Bana aşağıdaki formu kullanarak ulaşabilirsiniz
        </p>
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-full h-1 my-8 bg-gradient-to-r from-[#ED1944] via-fuchsia-500 to-[#EF8D2D] border-0 rounded-3xl" />
        </div>
      </div>

      <div className="flex justify-center items-center w-full">
        <div className="py-6 rounded-lg md:w-1/2 lg:w-1/2">
          <form onSubmit={handleSubmit}>
            <div className="-mx-2 md:items-center md:flex">
              <div className="flex-1 px-2">
                <label className="block mb-2 text-sm text-black dark:text-gray-200">
                  İsim
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Ali"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div className="flex-1 px-2 mt-4 md:mt-0">
                <label className="block mb-2 text-sm text-black dark:text-gray-200">
                  Soyisim
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Demir"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="block mb-2 text-sm text-black">Email Adresi</label>
              <input
                type="email"
                name="email"
                required
                placeholder="ben@example.com"
                value={formData.email}
                onChange={handleChange}
                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div className="w-full mt-4">
              <label className="block mb-2 text-sm text-black">Mesaj</label>
              <textarea
                name="message"
                className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Mesaj"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="flex items-center justify-center w-full">
              <button
                className={`w-1/2 my-4 font-medium text-center tracking-wide transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3 px-6 bg-gradient-to-tr from-[#ED1944] to-[#EE8621] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] rounded-full ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                type="submit"
                disabled={isSubmitting}
              >
                Gönder
              </button>
            </div>

            {submissionSuccess === true && (
              <p className="text-green-500 text-center">Mesajınız başarıyla gönderildi! En kısa zamanda dönüş yapacağım.</p>
            )}
            {submissionSuccess === false && (
              <p className="text-red-500 text-center">{errorMessage}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
