import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Res1 from "../assets/404-1.png";
import Res2 from "../assets/404-2.png";

const NotFound = () => {
  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-r from-[#e9e8fd] to-[#ffffff] font-montserrat">
      <Navbar />
      <div className="flex flex-col px-16 lg:px-56 pt-12 lg:pt-24 gap-y-52 lg:gap-y-72">
        <div class="items-center flex justify-center flex-col-reverse lg:flex-row">
          <div class="xl:pt-12 w-full xl:w-1/2 relative pb-12 lg:pb-0">
            <div class="relative">
              <div class="absolute">
                <div class="">
                  <h1 class="my-2 text-gray-900 font-bold text-7xl">404</h1>
                  <h1 class="my-2 text-gray-600 font-bold text-2xl">
                    Gitmek istediğiniz sayfa henüz oluşturulmamış
                  </h1>
                  <p class="my-2 text-gray-800">
                    Anasayfaya dönmeye ne dersiniz?
                  </p>
                  <button
                    onClick={() => (window.location.href = "https://alihandemirdas.com.tr")}
                    class="font-medium text-center sm:w-full lg:w-auto transition-all my-2 disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-4 px-8 bg-gradient-to-tr from-[#ED1944] to-[#EE8621] text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] rounded-2xl"
                  >
                    Anasayfa
                  </button>
                </div>
              </div>
              <div>
                <img src={Res2} />
              </div>
            </div>
          </div>
          <div>
            <img src={Res1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
