import React from "react";

const educationData = [
  {
    degree: "Bachelor of Computer Engineering",
    institution: "Necmettin Erbakan University",
    year: "2020 - 2024",
    description: "Graduated with a focus on software engineering and AI.",
  },
  {
    degree: "Science High School",
    institution: "Bor Şehit Ramazan Konuş Fen Lisesi",
    year: "2016 - 2020",
    description: "Graduated with a focus on software engineering and AI.",
  },
];

const EduExp = () => {
  return (
    <div id="deneyim" className="grid lg:grid-cols-6 gap-x-10 my-5">
      <div className="flex flex-col lg:col-span-2 w-full">
        <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#ED1944] via-fuchsia-500 to-[#EF8D2D] bg-[length:100%_5px] bg-no-repeat bg-bottom">
          Eğitim
        </h1>
        <div class="grid my-5">
          <div class="flex flex-col mb-4 lg:mb-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="40"
              height="40"
              className="mb-4 rounded-lg border border-gray-300 p-2"
            >
              <path d="M219.3 .5c3.1-.6 6.3-.6 9.4 0l200 40C439.9 42.7 448 52.6 448 64s-8.1 21.3-19.3 23.5L352 102.9V160c0 70.7-57.3 128-128 128s-128-57.3-128-128V102.9L48 93.3v65.1l15.7 78.4c.9 4.7-.3 9.6-3.3 13.3s-7.6 5.9-12.4 5.9H16c-4.8 0-9.3-2.1-12.4-5.9s-4.3-8.6-3.3-13.3L16 158.4V86.6C6.5 83.3 0 74.3 0 64C0 52.6 8.1 42.7 19.3 40.5l200-40zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6z" />
            </svg>
            <h5 className="text-xl lg:text-2xl font-bold">
              Bilgisayar Mühendisliği
            </h5>
            <h6 className="text-lg font-medium w-full">
              Necmettin Erbakan Üniversitesi
            </h6>
            <p>Ekim 2020 - Temmuz 2024</p>
            <h5 class="text-sm font-light">Konya, Türkiye</h5>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:col-span-4">
        <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#ED1944] via-fuchsia-500 to-[#EF8D2D] bg-[length:100%_5px] bg-no-repeat bg-bottom">
          Deneyim
        </h1>

        <div class="grid grid-cols-1 my-5">
          <ol class="relative border-s border-gray-400 ml-4">
            <li class="mb-10 ms-10">
              <span class="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full -start-3 ring-8 ring-gray-800 dark:ring-gray-900 dark:bg-blue-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  className="w-3 h-3 text-blue-800"
                >
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                </svg>
              </span>
              <h5 class="text-xl lg:text-2xl font-bold">
                Intern Full-Stack Developer
              </h5>
              <h6 class="text-lg font-medium">AKINROBOTICS</h6>
              <p>Şubat 2024 - Haziran 2024</p>
              <h5 class="text-sm font-light">Konya, Türkiye</h5>
              <div class="flex flex-col col-span-4 my-6">
                <ul class="list-disc list-inside text-gray-700 space-y-4">
                  <li>
                    Şirketin aktif olarak kullandığı uygulamaların (AR-GUI -
                    Diagnostik (Test) Yazılımları) backend ve frontend
                    geliştirmeleri ve bakımları yapıldı.
                  </li>
                  <li>
                    Şu anda yeni bir sürüm olan ve kullanımda olan Donanım,
                    Yazılım, Elektromekanik Teşhis yazılımının ek
                    yükseltmelerini ve bakımını tamamladım.
                  </li>
                  <li>
                    Şirkette kullanılan, bilgi sahibi olduğum ve önemli olduğunu
                    düşündüğüm teknolojiler: Node.js, Typescript, React, Redux,
                    Websocket, TailwindCSS, Material UI, Observer Tasarım
                    Deseni, Önbellek Teknolojisi, MySQL.
                  </li>
                  <li>
                    Şu anda aktif olarak kullanılmakta olan şirketin servis
                    robotu için Figma kullanarak ekran arayüzü tasarımında yer
                    aldım.
                  </li>
                </ul>
              </div>
            </li>
            <li class="mb-10 ms-10">
              <span class="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full -start-3 ring-8 ring-gray-800 dark:ring-gray-900 dark:bg-blue-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  className="w-3 h-3 text-blue-800"
                >
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                </svg>
              </span>
              <h5 class="text-xl lg:text-2xl font-bold">
                Intern Software Developer
              </h5>
              <h6 class="text-lg font-medium">SRL Teknoloji</h6>
              <p>Temmuz 2022 - Ağustos 2022</p>
              <h5 class="text-sm font-light">İstanbul, Türkiye</h5>
              <div class="flex flex-col col-span-4 my-6">
                <ul class="list-disc list-inside text-gray-700 space-y-4">
                  <li>
                    6 haftalık stajım boyunca genel olarak Node.js, Express ve
                    MongoDB üzerinde çalıştım ve eğitim aldım. Basit API'ler ve
                    arayüzler geliştirdim. Linux'u deneyimledim, komutlarla
                    çalıştım
                  </li>
                  <li>
                    Git ve Github kullanımını deneyimledim. Bir startup olduğu
                    için iletişimin ve senkron çalışmanın önemini gördüm ve bu
                    şekilde çalıştım.
                  </li>
                  <li>
                    Şirketin ana işi Nesnelerin İnterneti (IoT) üzerine olduğu
                    için bu konuda detaylı bilgiye sahip oldum. Son 2 hafta
                    içinde şirket için Wordpress kullanarak 2 web sitesi
                    yayınladım.
                  </li>
                </ul>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default EduExp;
